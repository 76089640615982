<template>
	<div class="row box">
		<div class="col-4">
            <div class="form-group">
                <label for="couleur" class="col-form-label">{{ $t("monte.congelation.couleur_default") }} *</label>
                <e-select
                    v-model="couleur"
                    id="couleur"
                    track-by="semencecouleur_code"
                    :placeholder="$t('monte.stock_semence.selectionnez_couleur')"
                    :selectedLabel="$t('global.selected_label')"
                    :options="all_couleurs"
                    :searchable="true"
                    :allow-empty="false"
                    :show-labels="false"
					:loading="loadingCouleur"
                >
					<template slot="option" slot-scope="{ option }">{{ $t('monte.couleur.'+option.semencecouleur_code) }}</template>
	                <template slot="singleLabel" slot-scope="{ option }">{{ $t('monte.couleur.'+option.semencecouleur_code) }}</template>
	                <template slot="noOptions">{{ $t('global.list_empty') }}</template>
				</e-select>
            </div>
        </div>

		<div class="col-4">
            <div class="form-group">
               <label for="stockage" class="col-form-label">{{ $t("monte.congelation.stockage_default") }} *</label>
               <e-select
					v-model="stockage"
					id="stockage"
					track-by="full_name"
					label="full_name"
					:placeholder="$t('monte.stock_semence.rechercher_categorie')"
					:selectedLabel="$t('global.selected_label')"
					:options="all_localisations_stockage"
					:searchable="true"
					:show-labels="false"
					:allow-empty="true"
					:sortable="false"
					:loading="loadingLoc"
				>
					<template slot="option" slot-scope="props"><span :style="`padding-left: ${25 * (props.option.indent || 0) }px;`">{{ props.option.full_name }}</span></template>
				</e-select>
            </div>
        </div>

		<div class="col-4">
            <div class="form-group">
               <label for="prestockage" class="col-form-label">{{ $t("monte.congelation.prestockage_default") }} *</label>
               <e-select
					v-model="prestockage"
					id="prestockage"
					track-by="full_name"
					label="full_name"
					:placeholder="$t('monte.stock_semence.rechercher_categorie')"
					:selectedLabel="$t('global.selected_label')"
					:options="all_localisations_prestockage"
					:searchable="true"
					:show-labels="false"
					:allow-empty="true"
					:sortable="false"
					:loading="loadingLoc"
				>
					<template slot="option" slot-scope="props"><span :style="`padding-left: ${25 * (props.option.indent || 0) }px;`">{{ props.option.full_name }}</span></template>
				</e-select>
            </div>
        </div>
        <div class="col-12 text-center">
        	<button class="btn btn-primary" @click="checkForm"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse />{{ $t('global.sauvegarder') }}</button>
        </div>
	</div>
</template>

<script type="text/javascript">
	import StockSemence from "@/mixins/StockSemence.js"
	import Congelation from "@/mixins/Congelation.js"

	export default {
		name: 'StallionSettings',
		mixins: [StockSemence, Congelation],
		props: ['horse_id'],
		data () {
			return {
				loadingCouleur: false,
				loadingLoc: false,
				all_couleurs: [],
				all_localisations_stockage: [],
				all_localisations_prestockage: [],
				couleur: null,
				stockage: null,
				prestockage: null,
				processing: false
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.loadingCouleur = true
				this.all_couleurs = await this.getAllSemenceCouleur()
				this.loadingCouleur = false

				this.loadingLoc = true
				const all_localisations = await this.getLocalisationSemenceFullName()
				this.all_localisations_stockage = all_localisations.filter(loc => !loc.semencelocalisation_prestockage)
				this.all_localisations_prestockage = all_localisations.filter(loc => loc.semencelocalisation_prestockage)
				this.loadingLoc = false

				const setting = await this.getStallionSettings(this.horse_id)
				if(setting) {
					this.couleur = this.all_couleurs.find(c => c.semencecouleur_id == setting.semencestallion_couleur)
					this.stockage = all_localisations.find(loc => loc.semencelocalisation_id == setting.semencestallion_stockage)
					this.prestockage = all_localisations.find(loc => loc.semencelocalisation_id == setting.semencestallion_prestockage)
				}
			},

			async checkForm() {
				if(!this.couleur || !this.stockage || !this.prestockage) {
					return false
				}

				this.processing = true
				if(this.setting_id) {
					await this.updateStallionSettings(this.setting_id, this.couleur.semencecouleur_id, this.stockage.semencelocalisation_id, this.prestockage.semencelocalisation_id)
				}
				else {
					await this.createStallionSettings(this.horse_id, this.couleur.semencecouleur_id, this.stockage.semencelocalisation_id, this.prestockage.semencelocalisation_id)
				}
				this.successToast('toast.info_save_succes')
				this.processing = false
			}
		}
	}

</script>